@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
}

.listingCard {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  box-shadow: var(--boxShadowSectionLocationHover);
  border-radius: 10px;
  padding: 5px;
  position: relative;

  @media (--viewportMedium) {
    padding: 10px;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
    }
  }
}

.rootForImage {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rootForImageShadow {
  position: absolute;
  bottom: -14px;
  width: calc(100% - 40px);
  left: 20px;
  filter: blur(10px);
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.info {
  /* Layout */
  padding: 10px 0 0px 0;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding: 30px 0 0px 0;
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: auto;
  line-height: 1em;
  padding-bottom: 10px;

  @media (--viewportMedium) {
    padding-top: 10px;
  }
}

.priceValue {
  /* Font */
  color: var(--colorGrey900);
  font-weight: var(--fontWeightBold);
  line-height: 1em;

  @media (--viewportMedium) {}

}

.perUnit {
  /* Font */
  font-size: 12px;

  display: inline-flex;
  align-items: center;
  gap: 5px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--colorGrey);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitProfilePage {
  /* Font */
  font-size: 12px;

  display: inline-flex;
  align-items: center;
  /* gap: 5px;  */

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--colorGrey);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitIcon {
  display: none;

  @media (--viewportMedium) {
    display: inline-block;
  }

}

.mainInfo {
  /* display: flex;
  flex-direction: column; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  /* Font */
  composes: textSmall from global;
  /* font-weight: var(--fontWeightRegular); */
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);
  margin-bottom: 5px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;

  @media (--viewportMedium) {
    font-size: 16px;
    white-space: normal;
    overflow: visible;
    text-overflow: ellipsis;
    max-width: none;
    margin-bottom: 5px;
  }
}

.titleInProfilePage {
  /* Font */
  composes: textSmall from global;
  /* font-weight: var(--fontWeightRegular); */
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);
  margin-bottom: 5px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 147px;

  @media (--viewportMedium) {
    font-size: 16px;
    white-space: normal;
    overflow: visible;
    text-overflow: ellipsis;
    max-width: none;
    margin-bottom: 5px;
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey);

  display: flex;
  align-items: center;
  gap: 5px;
  /* Remove default margins from font */
  margin-top: 0;
  /* margin-bottom: 5px; */
  font-size: 12px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0px;
    font-size: 13px;
    margin-top: 6px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.discountTag {}

.discountLabel {
  position: absolute;
  top: 0;
  right: 0;
}