/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */

/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */

*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */
}

/**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritence in all browsers (opinionated).
  */

::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
  * 1. Add border box sizing in all browsers (opinionated).
  * 2. Add the default cursor in all browsers (opinionated).
  * 3. Prevent font size adjustments after orientation changes in IE and iOS.
  */

html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  */

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
  * Remove the margin in all browsers (opinionated).
  */

body {
  margin: 0;
  background: pink;
}

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  * 1. Add the correct display in IE.
  */

figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
  * Add the correct margin in IE 8.
  */

figure {
  margin: 1em 40px;
}

/**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */

nav ol,
nav ul {
  list-style: none;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
    ========================================================================== */

/**
  * 1. Remove the gray background on active links in IE 10.
  * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
  */

a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
  * 1. Remove the bottom border in Firefox 39-.
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  */

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
  * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
  */

b,
strong {
  font-weight: inherit;
}

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */

b,
strong {
  font-weight: bolder;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
  * Add the correct font style in Android 4.3-.
  */

dfn {
  font-style: italic;
}

/**
  * Add the correct background and color in IE 9-.
  */

mark {
  background-color: #ffff00;
  color: #000000;
}

/**
  * Add the correct font size in all browsers.
  */

small {
  font-size: 80%;
}

/**
  * Prevent `sub` and `sup` elements from affecting the line height in
  * all browsers.
  */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
  * Remove the text shadow on text selections (opinionated).
  * 1. Restore the coloring undone by defining the text shadow (opinionated).
  */

::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
    ========================================================================== */

/*
  * Change the alignment on media elements in all browers (opinionated).
  */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
  * Add the correct display in IE 9-.
  */

audio,
video {
  display: inline-block;
}

/**
  * Add the correct display in iOS 4-7.
  */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
  * Remove the border on images inside links in IE 10-.
  */

img {
  border-style: none;
}

/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */

svg {
  fill: currentColor;
}

/**
  * Hide the overflow in IE.
  */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
    ========================================================================== */

/**
  * Collapse border spacing
  */

table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
    ========================================================================== */

/**
  * Remove the margin in Firefox and Safari.
  */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
  * Inherit styling in all browsers (opinionated).
  */

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
  * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
  *    controls in Android 4.
  * 2. Correct the inability to style clickable types in iOS and Safari.
  */

button,
html [type="button"],
/* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
  * Remove the inner border and padding in Firefox.
  */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
  * Restore the focus styles unset by the previous rule.
  */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
  * 1. Add the correct display in IE 9-.
  * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */

progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
  * 1. Remove the default vertical scrollbar in IE.
  * 2. Change the resize direction on textareas in all browsers (opinionated).
  */

textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */
}

/**
  * 1. Add the correct box sizing in IE 10-.
  * 2. Remove the padding in IE 10-.
  */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
  * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
  */

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
    ========================================================================== */

/*
  * Add the correct display in IE 9-.
  * 1. Add the correct display in Edge, IE, and Firefox.
  */

details,
/* 1 */
menu {
  display: block;
}

/*
  * Add the correct display in all browsers.
  */

summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  */

canvas {
  display: inline-block;
}

/**
  * Add the correct display in IE.
  */

template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
    ========================================================================== */

/*
  * Remove the tapping delay on clickable elements (opinionated).
  * 1. Remove the tapping delay in IE 10.
  */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
}

/**
  * Add the correct display in IE 10-.
  */

[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
    ========================================================================== */

/**
  * Change the cursor on busy elements (opinionated).
  */

[aria-busy='true'] {
  cursor: progress;
}

/*
  * Change the cursor on control elements (opinionated).
  */

[aria-controls] {
  cursor: pointer;
}

/*
  * Change the display on visually hidden accessible elements (opinionated).
  */

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements (opinionated).
  */

[aria-disabled] {
  cursor: default;
}

.ant-switch,
.ant-switch:hover:not(.ant-switch-disabled) {
  background-color: #fff;
  border: solid 1px var(--marketplaceColor);
  height: 25px;
}

.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: var(--marketplaceColor);
}

.ant-switch .ant-switch-handle::before {
  background-color: var(--marketplaceColor);
  box-shadow: none;
}

.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #fff;

}

.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
  color: var(--marketplaceColor);
}

/* RINU CUSTOM CODE*/

/* Drawer */
.ant-drawer-title {
  text-transform: uppercase;
}

.ant-drawer .ant-drawer-header-title {
  flex: unset;
}

.ant-drawer-header {
  padding: 0 !important;
}


.ant-drawer-left>.ant-drawer-content-wrapper {
  box-shadow: var(--boxShadowPopup);
}

.ant-drawer .ant-drawer-close {
  background: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 10px 30px;
}

.ant-drawer .ant-drawer-close:hover {
  background-color: #f1f1f1;
}

.ant-drawer .ant-drawer-close span {
  color: #000000;
}

.ant-drawer-body {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: unset !important;
}

.ant-drawer-body div {
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  width: 30%;
  margin-bottom: 20px;
}

.ant-drawer-body img {
  width: 40px;
}

.ant-drawer-body div span {
  display: block;
}

.ant-drawer-body a span:first-child {
  background: #f9adc529;
  display: block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 5px auto;
  text-align: center;
  line-height: 60px;
}

.ant-drawer-body a:hover span:first-child {
  background: var(--marketplaceColor);
}

.ant-drawer-body a:hover span img {
  filter: brightness(0) invert(1);
}

.ant-badge .ant-badge-count {
  box-shadow: none;
}

.ant-ribbon {
  color: var(--colorWhite);
  background-color: #e8666a;
  height: 35px;
  line-height: 35px;
  padding: 0px 20px;
}

@media only screen and (max-width: 767px) {
  .ant-ribbon {
    height: 20px;
    line-height: 20px;
    padding: 0px 10px;
  }
}

.ant-ribbon-text {
  font-size: 16px;
}

.ant-ribbon .ant-ribbon-corner {
  color: #e8666a;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ant-checkbox-wrapper,
.ant-radio-wrapper {
  font-size: 16px;
  font-weight: 600;
  color: var(--colorGrey700);
}

/* Modal */
.ant-modal-content {
  padding: 10px !important;

  @media (--viewportLarge) {
    padding: 15px !important;
  }

  iframe {
    height: 300px;

    @media (--viewportLarge) {
      height: 600px;
    }

  }
}

.ant-modal-close {
  background: #fff !important;
  margin: -36px -13px 0 0;

  @media (--viewportLarge) {
    margin: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-float-btn.ant-float-btn-default.ant-float-btn-circle {
    display: none;
  }
}

/* Form */
.ant-radio-wrapper .ant-radio-checked::after {
  border: 1px solid var(--marketplaceColor);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColor);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--marketplaceColor);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--marketplaceColor);
}

/* Menu */
.menu .anticon {
  font-size: 26px;
}

.ant-dropdown {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.ant-dropdown .ant-dropdown-menu.ant-dropdown-menu-vertical {
  padding: 0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 17px 20px 17px 30px;
  border-bottom: solid 1px #f1f1f1;
  border-radius: 0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:last-child {
  border-bottom: none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item a:hover {
  text-decoration: none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item a span {
  display: inline-flex;
  gap: 20px;
  align-items: center;
  color: #6a798a;
  font-size: 16px;
}

.ant-badge .ant-badge-dot {
  width: 8px;
  min-width: 8px;
  height: 8px;
  right: 5px;
  top: 5px;
}

.ant-menu {
  padding-top: 0;
  background: none;

  @media (--viewportLarge) {
    padding-top: 32px;
  }
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  /* @media only screen and (max-width: 550px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }*/
}

.ant-menu-dark.ant-menu-submenu-popup>.ant-menu {
  background: var(--colorPurple);
  border-top-left-radius: 0;
  margin-top: -33px;
}

.ant-menu-dark.ant-menu-submenu-popup>.ant-menu .ant-menu-item-selected {
  background: none;
}

.ant-menu-dark .ant-menu-submenu {
  border-bottom: solid 1px #3e4255;
  border-radius: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;

  /* @media only screen and (max-width: 550px) {
    border-right: solid 1px rgba(255, 255, 255, 0.15);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
}

.ant-menu .ant-menu-submenu-arrow {
  /* @media only screen and (max-width: 550px) {
    display: none;
  } */
}

.ant-menu-dark .ant-menu-item {
  border-bottom: solid 1px #3e4255;
  border-radius: 0 !important;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto !important;
  width: 100%;

  /* @media only screen and (max-width: 550px) {
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  } */
}

.ant-menu-dark .ant-menu-item {
  margin: 0;
}

.ant-menu-dark .ant-menu-item-selected {
  background: rgba(0, 0, 0, 0.25);
}

.ant-menu-dark .ant-menu-submenu-title {
  color: #ffffff;
  padding-right: 40px;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto !important;

  /* @media only screen and (max-width: 550px) {
    border-radius: 0;
    padding: 0 !important;
    justify-content: center;
  } */
}

.ant-menu-dark .ant-menu-title-content {
  color: #ffffff;
  padding-right: 40px;
  margin: 0;

  /* @media only screen and (max-width: 550px) {
    display: none;
  } */
}


.ant-menu-dark .ant-menu-item-icon {
  font-size: 24px !important;
  color: #ffffff;
  margin-right: 10px;

  /* @media only screen and (max-width: 550px) {
    margin: 10px;
  } */
}

.ant-menu-dark .ant-menu-item-icon svg {
  fill: #ffffff;
}

.ant-menu-dark .ant-menu-title-content {
  font-weight: var(--fontWeightRegular);
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: rgba(0, 0, 0, 0.25);

  /* @media only screen and (max-width: 550px) {
    background: var(--colorDarkGrey);
    border-top: solid 1px rgba(255, 255, 255, 0.15);
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 10;
    top: 108px;

    span {
      display: block;
      padding: 10px;
    }
  } */
}


/* @media only screen and (max-width: 550px) {

  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-active .ant-menu.ant-menu-sub.ant-menu-inline,
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active.ant-menu-submenu-selected .ant-menu.ant-menu-sub.ant-menu-inline {
    display: block;
  }

  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu.ant-menu-sub.ant-menu-inline {
    display: none;
  }
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-active .ant-menu.ant-menu-sub.ant-menu-inline.ant-menu-hidden,
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active.ant-menu-submenu-selected .ant-menu.ant-menu-sub.ant-menu-inline.ant-menu-hidden {
    display: none;
  }

} */

.ant-menu-item.ant-menu-item-only-child {
  border-bottom: none;
  /* @media only screen and (max-width: 550px) {
    border-top: solid 1px rgba(255, 255, 255, 0.15);
  } */
}


@media only screen and (max-width: 768px) {
  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: repeat(2, 1fr);
    justify-content: center;
    justify-items: stretch;
    align-items: stretch;
    align-content: center;
    margin-left: -82px;
  }

  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal .ant-menu-title-content {
    display: none;
  }

  .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal,
  .ant-menu-overflow-item.ant-menu-item {
    display: flex;
    align-items: center;
    padding: 0;
    max-width: 80px;
    width: 100%;
    justify-content: center;
    opacity: 1 !important;
    order: unset !important;
    overflow: visible !important;
    position: relative !important;
    flex: auto;
    height: auto !important;

    .ant-menu-submenu-title {
      padding: 10px;
    }
  }

  .ant-menu-light>.ant-menu .ant-menu-item-selected {
    color: var(--marketplaceColor);
  }

  .ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: #ffe3ec;
  }

  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light li:last-child {
    display: none;
  }

  .ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected::after,
  .ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected::after,

  .ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item:hover::after,
  .ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu:hover::after,
  .ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-active::after,
  .ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-active::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-active::after,
  .ant-menu-light.ant-menu-horizontal>.ant-menu-item-open::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-open::after,
  .ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-open::after,
  .ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-open::after {
    border-bottom-color: var(--marketplaceColor);
  }
}






.ant-table-wrapper .ant-table-pagination.ant-pagination {
  justify-content: center;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
  padding: 0;
  border-color: transparent;
}

.ant-pagination .ant-pagination-item {
  align-items: center;
  border-radius: 50%;
  color: var(--colorGrey700);
  display: flex;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
  padding: 0;
  border-color: transparent;
}

.ant-pagination .ant-pagination-item-active {
  background: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff;
}

.ant-btn-primary.ant-btn-solid {
  background: var(--marketplaceColor);
}

/* OWL CAROUSEL */
.owl-item a:hover {
  text-decoration: none;
}

.owl-carousel.owl-scoll .owl-nav .owl-prev,
.owl-carousel.owl-scoll .owl-nav .owl-next {
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1px var(--colorGrey);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  margin: 15px 0;

  @media (--viewportMedium) {
    margin: 15px;
  }
}

.owl-carousel.owl-scoll .owl-nav .owl-next {
  right: 0;
  left: unset;
}

@media only screen and (max-width: 550px) {
  .owl-carousel.owl-scoll {
    .owl-item:nth-child(10n+1) div div {
      background-color: #d84452;
    }

    .owl-item:nth-child(10n+2) div div {
      background-color: #e7553e;
    }

    .owl-item:nth-child(10n+3) div div {
      background-color: #fdb747;
    }

    .owl-item:nth-child(10n+4) div div {
      background-color: #88c053;
    }

    .owl-item:nth-child(10n+5) div div {
      background-color: #35ba9b;
    }

    .owl-item:nth-child(10n+6) div div {
      background-color: #3aadd9;
    }

    .owl-item:nth-child(10n+7) div div {
      background-color: #4a89d9;
    }

    .owl-item:nth-child(10n+8) div div {
      background-color: #9479da;
    }

    .owl-item:nth-child(10n+9) div div {
      background-color: #d46fab;
    }

    .owl-item:nth-child(10n+10) div div {
      background-color: #e2b691;
    }
  }
}

.owl-carousel.owl-service .owl-stage {
  padding-top: 15px;

  @media (--viewportMedium) {
    padding-top: 100px;
  }
}

.owl-carousel.owl-service .owl-item {
  /* transition: transform 0.3s ease; */
  transform: scale(0.5, 0.5);

  @media only screen and (max-width: 550px) {
    &:not(.active) {
      opacity: 0;
    }
  }
}

.owl-carousel.owl-service .owl-item.active.center {
  transform: scale(1);
}

.owl-carousel.owl-service .owl-item.active {
  transform: scale(0.75, 0.75);
}

.owl-carousel.owl-service .owl-item.active.center+.active {
  transform: scale(0.5, 0.5);

  @media (--viewportMedium) {
    transform: scale(0.75, 0.75);
  }

  @media (--viewportLarge) {
    transform: scale(0.75, 0.75);
  }

  @media only screen and (max-width: 550px) {
    transform: scale(0.75, 0.75);
  }
}

.owl-carousel.owl-service .owl-item.active.center+.active+.active+.active {
  transform: scale(0.5, 0.5);
}

.owl-carousel.owl-service .owl-nav {
  position: absolute;
  top: 0;
  left: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  @media (--viewportMedium) {
    top: 10px;
    left: 50%;
    margin-left: -46px;
    display: block;
    height: auto;
  }
}

.owl-carousel.owl-service .owl-nav .owl-prev,
.owl-carousel.owl-service .owl-nav .owl-next {
  background-color: #2c2c2c;
  color: #fff;
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 3px;

  @media only screen and (max-width: 550px) {
    background: none;
    color: var(--colorGrey);
  }
}

.owl-carousel.owl-loc {
  margin-right: -240px;
}

.owl-carousel.owl-loc .owl-nav {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportLarge) {
    position: absolute;
    bottom: 10px;
  }
}

.owl-carousel.owl-loc .owl-nav .owl-prev,
.owl-carousel.owl-loc .owl-nav .owl-next {
  color: #fff;
  font-size: 30px;
  margin: 5px;
}

.owl-carousel.owl-loc .owl-nav .owl-prev.disabled,
.owl-carousel.owl-loc .owl-nav .owl-next.disabled {
  opacity: 0.2;
  font-size: 20px;
}

.owl-carousel.owl-loc .owl-item {
  padding: 0 16px 50px 16px;
  transition: all .2s ease-in-out;
  transform: scale(0.8);
}

.owl-carousel.owl-loc .owl-item.center {
  transform: scale(1);
}

.owl-carousel.owl-loc .owl-dots {
  text-align: center;
}

.owl-carousel.owl-loc .owl-dots .owl-dot {
  height: 5px;
  width: 5px;
  background: #ffffff57;
  margin: 0 5px;
  border-radius: 50%;
}

.owl-carousel.owl-loc .owl-dots .owl-dot.active {
  background: #ffffff;
}

.owl-carousel.owl-recent {
  z-index: 3;
}

.owl-carousel.owl-recent .owl-item {
  padding: 0 10px 35px 14px;
}

.owl-carousel.owl-recent .owl-nav {
  text-align: center;
}

.owl-carousel.owl-recent .owl-nav .owl-prev,
.owl-carousel.owl-recent .owl-nav .owl-next {
  background-color: #2c2c2c;
  color: #fff;
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.CalendarDay__default:hover {
  background: var(--marketplaceColor) !important;
  border: 1px double #e4e7e7;
  color: inherit;
  border-radius: 50%;
  color: #FFF !important;
}

.CalendarDay:hover .renderedDay {
  background: none !important;
  color: #FFF !important;
}

.ant-affix {
  font-family: var(--fontFamily);
  display: none;

  @media (min-width: 1220px) {
    display: block;
    z-index: 99999 !important;
    left: 305px;
    height: 72px !important;
    width: auto !important;
    border-right: solid 1px var(--colorLight);
    border-left: solid 1px var(--colorLight);
  }
}

.ant-affix>div {
  box-shadow: none;
  border-bottom: none;
  border-radius: 0;
  background: none;
  padding: 0 15px;
  align-items: center;
  height: 72px;
}

.ant-affix>div>div {
  background: none;
  border: none;
}

.ant-affix button {
  padding: 10px 15px;
  font-size: 14px;
}

.ant-affix button img {
  width: 16px;
  vertical-align: sub;
}

.ant-affix input {
  border: none !important;
  border-radius: 0;
  font-size: 14px;
  background: transparent;
  width: 170px;
}

.ant-affix label {
  color: var(--colorGrey);
  font-size: 12px !important;
}

.ant-select-single .ant-select-selector {
  font-size: 16px;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url("owl.video.play.png") no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #fff !important;
  background-color: var(--marketplaceColor) !important;
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 1.3em;
}

.rbc-show-more {
  color: var(--marketplaceColor) !important;
}

/* #portal-root>div:nth-child(10n+3) {
  top: 67px;
  position: relative;
  display: flex;
  justify-content: center;

  >div {
    z-index: 9;
    position: fixed;
    width: 95%;

    >div {
      border-radius: 10px;
      padding: 10px;
      min-height: calc(100vh - 100px);

      button {
        padding: 0;
        left: 22px;
        top: 22px;
        background: #fff;
        width: 30px;
        height: 30px;

        span {
          display: none;
        }
      }

      >div {
        height: calc(100vh - 100px);
      }
    }
  }
} */

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  .react-datepicker {
    font-family: var(--fontFamily);
    min-width: 300px;
    margin-top: 7px;
    padding: 20px;
    background-color: var(--colorWhite);
    border-top: 1px solid var(--colorGrey100);
    box-shadow: var(--boxShadowPopup);
    border-radius: var(--borderRadiusMedium);
    transition: var(--transitionStyleButton);


    .react-datepicker__navigation {
      top: 15px;
    }

    .react-datepicker__navigation-icon::before {
      border-color: var(--marketplaceColor);
      border-width: 2px 2px 0 0;
    }

    .react-datepicker__month-container {
      float: none;
    }

    .react-datepicker__header {
      background: none;
      border: none;
      border-radius: 0;
      padding: 0;
    }

    h2.react-datepicker__current-month {
      color: var(--colorGrey700);
      font-weight: var(--fontWeightBold);
      font-size: 18px;
      line-height: 20px;
    }

    .react-datepicker__day-names {
      font-weight: var(--fontWeightMedium);
      color: var(--colorGrey700);
      padding-top: 10px;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-size: 15px;
      line-height: 37px;
      letter-spacing: 0;
      /* color: var(--colorGrey700); */
      width: 38px;
      height: 38px;
      border-radius: 50%;

      &.react-datepicker__day--selected,
      &.react-datepicker__day--in-range {
        background-color: var(--marketplaceColor);
        color: #ffffff;
      }
    }

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      background-color: var(--marketplaceColor);
      color: #ffffff;
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
    .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
    .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
    .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range) {
      background-color: transparent !important;
      color: #000;
      border-radius: 50% !important;
    }

    /* Hover effect */
    .react-datepicker__day:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-selecting-range:hover {
      background-color: var(--marketplaceColor) !important;
      /* Light background on hover */
      color: #FFF !important;
      /* Maintain text color */
    }

  }

  & .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: transparent !important;
  }

  & .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: transparent !important;
  }

  & .react-datepicker__year-text--keyboard-selected {
    background-color: var(--marketplaceColor) !important;
  }

}


/* General button styles for all buttons */
.rbc-calendar .rbc-btn-group button {
  background-color: var(--marketplaceColor) !important;
  color: white !important;
  border-radius: 10px;
  border: 2px solid var(--marketplaceColor) !important;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  outline: none;
  /* Remove the default button outline */
}

/* Hover effect for buttons */
.rbc-calendar .rbc-btn-group button:hover {
  background-color: var(--marketplaceColor) !important;
  border-color: var(--marketplaceColor) !important;
  opacity: 0.9;
  /* Slight opacity on hover */
}

/* Styling for the active button (Darker shade) */
.rbc-calendar .rbc-btn-group .rbc-active button {
  background-color: #8e0b35 !important;
  /* Darker shade for active button */
  border-color: #8e0b35 !important;
  /* Matching border color */
  color: white !important;
  /* Ensure the text color stays white */
}

/* Active button hover effect */
.rbc-calendar .rbc-btn-group .rbc-active button:hover {
  background-color: #8e0b35 !important;
  /* Darker color on hover */
  border-color: #8e0b35 !important;
  opacity: 1;
  /* No opacity on hover for active button */
}

/* Ensure the active button doesn't revert to grey when clicked */
.rbc-calendar .rbc-btn-group .rbc-active button:active {
  background-color: #8e0b35 !important;
  /* Keep it darkened */
  border-color: #8e0b35 !important;
  color: white !important;
  /* Ensure the text stays white */
  box-shadow: none;
  /* Disable default active box-shadow */
}

.rbc-month-view, .rbc-time-view {
  width: 320px !important;
  min-height: 500px;
  @media (--viewportMedium) {
    width: 100% !important;
  }
}

.rbc-active {
  background-color: #8e0b35 !important;
  /* Keep it darkened */
}

.ant-checkbox-wrapper,
.ant-radio-group,
.ant-radio {
  color: var(--colorGrey700);
  font-weight: 600;
}

.ant-menu-submenu-placement-bottomRight ul {
  margin-bottom: 10px
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
  border-left: 1px solid #ddd;
}
.rbc-agenda-table tr .rbc-header:first-child,
.rbc-agenda-table tr .rbc-header:nth-child(2),
.rbc-agenda-table tr .rbc-agenda-date-cell,
.rbc-agenda-table tr .rbc-agenda-time-cell {
  width: 145px !important;
  white-space: normal;
}
.rbc-agenda-table tr .rbc-header:last-child,
.rbc-agenda-table tr .rbc-agenda-event-cell {
  width: calc(100% - 290px) !important;
  white-space: normal;
}
@media only screen and (max-width: 550px) {
  
.rbc-agenda-table tr .rbc-header:first-child,
.rbc-agenda-table tr .rbc-header:nth-child(2),
.rbc-agenda-table tr .rbc-agenda-date-cell,
.rbc-agenda-table tr .rbc-agenda-time-cell {
  width: 90px !important;
}
.rbc-agenda-table tr .rbc-header:last-child,
.rbc-agenda-table tr .rbc-agenda-event-cell {
  width: calc(100% - 180px) !important;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  padding: 2px 5px;
  font-size: 13px;
  line-height: 1em;
}
}