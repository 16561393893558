@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 0px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px;
  }


}

.menuAllContainer {
  width: 100%;
}

.mainContainer {
  padding: 24px;
  height: 100%;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    composes: mainContainer from global;
    padding: 20px;
  }
}

.sideNav {
  composes: sideNav from global;

  @media only screen and (max-width: 550px) {
    position: fixed;
    bottom: 0;
    z-index: 10;
  }
}

.tabs {
  composes: tabs from global;
}

.tab {
  composes: tab from global;
}

.toggleBtn {
  position: absolute;
  right: -50px;
  background: var(--colorDarkGrey) !important;
  font-size: 18px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

@media only screen and (max-width: 768px) {
  .toggleBtn {
    display: none;
  }
}

.dashMenu {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.dashMobileMenu {
  @media (--viewportMedium) {
    display: none;
  }
}

.mobileMenu5 {
  grid-template-columns: repeat(6, 1fr) !important;
}

.mobileMenu4 {
  grid-template-columns: repeat(5, 1fr) !important;
}

.mobileMenu3 {
  grid-template-columns: repeat(4, 1fr) !important;
}