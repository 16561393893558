@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightRegular);

  color: var(--colorLight);
  padding: 10px;
  height: 100%;

  /* push tabs against bottom of tab bar */
  margin-top: auto;
 

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--colorLight);
  }

  @media (--viewportLarge) {

    font-size: 16px;
    height: unset;
    width: 100%;
    margin: 0;
    text-align: left;
    transition: var(--transitionStyleButton);
    border-bottom: solid 1px #3e4255;

    padding: 15px 24px;

    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    background: url(img/arrow-right.png) no-repeat right 12px center;
  

    &:hover {
      background-color: rgba(0,0,0,0.1);
    }
    span{
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.selectedLink {
  background-color: rgba(0,0,0,0.1);
  color: var(--colorLight);
  box-shadow: inset 0px -3px 0px var(--marketplaceColor);

  @media (--viewportLarge) {

    &:hover {
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}
